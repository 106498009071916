import React from 'react';


export default () => {

  return (
    <>
      <p>I love you.</p>
      <p>You are the sunlight that lifts me up</p>
      <p>That reaches my darkest cavities and pulls me up</p>
      <p>You show me paths I want to follow, and you have the courage to lead me down those paths</p>
      <p>You never hurt me, you show me how I have been hurt and you are my defense</p>
      <p>You are my punching bag for my rage and the comfort that I do not deserve in the aftermath</p>
      <p>My sunlight, I hope this day never ends.</p>

      <p>your moon,</p>
      <p>d~</p>
    </>
  );
};